<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :style="svgStyle"
    :viewBox="viewBox"
    :width="width"
    :height="height"
  >
    <path
      d="M42.21,24.74a5.89,5.89,0,0,1,4,1.74,1.49,1.49,0,0,1,0,2.48Q37.59,37.57,29,46.16a3.7,3.7,0,0,1-.28.28,1.41,1.41,0,0,1-1.92,0,5.71,5.71,0,0,1-1.34-1.67,7.45,7.45,0,0,1-.69-4.18,8.34,8.34,0,0,1,1.41-4.23.33.33,0,0,0,0-.46c-.33-.45-.65-.9-1-1.36l-5.64-7.89c-.15-.2-.23-.31-.5-.11a4.24,4.24,0,0,1-5.5-.35,1.5,1.5,0,0,1,0-2.52L23.67,13.52c.13-.12.25-.25.39-.36a1.41,1.41,0,0,1,1.71,0A4.28,4.28,0,0,1,26.61,19c-.24.31-.18.43.11.63q4.43,3.14,8.81,6.31a.87.87,0,0,0,1.2,0,8.7,8.7,0,0,1,4.42-1.21A3.87,3.87,0,0,1,42.21,24.74Zm-4.29,3.81-2.17,2.18A1.41,1.41,0,0,0,35.83,33a1.42,1.42,0,0,0,2.25.1L41,30.19a1.4,1.4,0,0,0,.42-1.66c-.24-.55-.73-.83-1.65-.82A2.82,2.82,0,0,0,37.92,28.55ZM49.81,50.21c.41,0,.45-.21.39-.45a4.71,4.71,0,0,0-.56-1.23,40,40,0,0,0-2.87-4.09c-2-2.54-4-5-6.1-7.42-.22-.26-.32-.26-.56,0-1,1.06-2.08,2.1-3.13,3.14-.16.16-.27.25,0,.46a130.52,130.52,0,0,0,10.36,8.25A12.83,12.83,0,0,0,49.24,50C49.44,50.09,49.67,50.16,49.81,50.21ZM31.54,62.44a30.91,30.91,0,0,1-12-59.38A30.91,30.91,0,0,1,53.39,53.39a30.86,30.86,0,0,1-21.85,9Zm0-59.26a28.34,28.34,0,1,0,20.05,8.3A28.21,28.21,0,0,0,31.54,3.18Z"
      :style="`fill: ${fill}`"
    />
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: '0 0 63.07 63.07' },
    fill: { type: String, default: '#FFFFFF' },
    svgClass: { type: String },
    svgStyle: { type: Object },
    width: { type: String, default: '63.07' },
    height: { type: String, default: '63.07' },
  },
};
</script>
