import _filesize from "filesize";
import dayjs from "./dayjs-local";

export function filesize(value) {
  return _filesize(value);
}

/**
 * Format the given date.
 */
export function date(value) {
  const newDate = value.replace("Z", "");
  return dayjs(newDate).format("MM/DD/YYYY");
}

export function dateLocalized(value) {
  return dayjs(value).format("MM/DD/YYYY");
}

/**
 * Format the given date.
 */
export function dateShort(value) {
  return dayjs(value).utc().format("ll");
}

export function dateShortLocalized(value) {
  return dayjs(value).format("MMM D, YYYY");
}

export function dateShortest(value) {
  return dayjs(value).utc().format("MM/DD/YYYY");
}

export function dateShortestLocalized(value) {
  return dayjs(value).format("MM/DD/YYYY");
}

export function dateEpochBased(value) {
  return dayjs(value).utc().format("MMMM DD, YYYY");
}

/**
 * Format the given date as a time.
 */
export function time(value) {
  return dayjs(value).format("h:mm A");
}

export function timeLocalized(value) {
  return dayjs(value).format("h:mm A");
}

/**
 * Format the given date (Vue datetime friendly).
 */
export function datepicker(value) {
  return dayjs(value).format();
}

/**
 * Format the given date as a timestamp.
 */
export function datetime(value) {
  return dayjs(value).utc().format("MMMM D, YYYY h:mm A");
}

export function datetimeLocalized(value) {
  return dayjs(value).format("MMMM D, YYYY h:mm A");
}

export function datetimeLocalizedShort(value) {
  return dayjs(value).format("MM/DD/YY, h:mm A");
}

export function datetimeLocalizedShortUnix(value) {
  return dayjs.unix(value).format("MM/DD/YY, h:mm A");
}

export function dateLocalizedShort(value) {
  return dayjs(value).format("MM/DD/YY");
}

export function bushelDateInputLocalizedFilter(value) {
  return dayjs(value).format("YYYY-MM-DD");
}

export function datetimeLocalizedAtTime(value) {
  return dayjs(value).format("MM/DD/YY @ h:mm A");
}

export function datetimeLocalizedJustTime(value) {
  return dayjs(value).format("h:mm A");
}

/**
 * Format and Return only Month, Day, and year functions
 */
export function monthFilter(value) {
  return dayjs(value).utc().format("MMMM YYYY");
}

export function monthYearFilter(value) {
  return dayjs(value).utc().format("MMM/YY");
}

export function monthFilterLocalized(value) {
  return dayjs(value).format("MMMM");
}

export function shortMonthFilter(value) {
  return dayjs(value).utc().format("MMM");
}

export function dayFilter(value) {
  return dayjs(value).utc().format("D");
}

export function dayFilterLocalized(value) {
  return dayjs(value).format("D");
}

export function yearFilter(value) {
  return dayjs(value).utc().format("YYYY");
}

export function yearFilterLocalized(value) {
  return dayjs(value).format("YYYY");
}

/**
 * Format the given date into a relative time.
 * This does not need a localized Version
 */
export function relative(value) {
  return dayjs(value).utc().local().fromNow();
}

/**
 * Format the given date into a calendar time, like relative but goes to 7/10/2011
 * format after a week of time
 */
export function calendartime(value) {
  return dayjs(value).locale("en-short").calendar(null, {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Tomorrow at] LT",
    lastWeek: "[last] dddd [at] LT",
    nextWeek: "dddd [at] LT",
    sameElse: "LL",
  });
}

export function calendartimeLocalized(value) {
  return dayjs(value).locale("en-short").calendar(null, {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Tomorrow at] LT",
    lastWeek: "[last] dddd [at] LT",
    nextWeek: "dddd [at] LT",
    sameElse: "LL",
  });
}

export function setupDateTimeLocallyWithTimezoneAttached(value) {
  const dt = dayjs(value, "YYYY-MM-DD h:mm A").toDate();
  const timezoneOffsetMin = new Date().getTimezoneOffset();
  let offsetHrs = parseInt(Math.abs(timezoneOffsetMin / 60), 10);
  let offsetMin = Math.abs(timezoneOffsetMin % 60);
  let timezoneStandard;

  if (offsetHrs < 10) offsetHrs = `0${offsetHrs}`;
  if (offsetMin < 10) offsetMin = `0${offsetMin}`;

  // Add an opposite sign to the offset
  // If offset is 0, it means timezone is UTC
  if (timezoneOffsetMin < 0) timezoneStandard = `+${offsetHrs}:${offsetMin}`;
  else if (timezoneOffsetMin > 0) timezoneStandard = `-${offsetHrs}:${offsetMin}`;
  else if (timezoneOffsetMin == 0) timezoneStandard = "Z";

  let currentDate = dt.getDate();
  let currentMonth = dt.getMonth() + 1;
  const currentYear = dt.getFullYear();
  let currentHrs = dt.getHours();
  let currentMins = dt.getMinutes();
  let currentSecs = dt.getSeconds();

  // Add 0 before date, month, hrs, mins or secs if they are less than 0
  currentDate = currentDate < 10 ? `0${currentDate}` : currentDate;
  currentMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
  currentHrs = currentHrs < 10 ? `0${currentHrs}` : currentHrs;
  currentMins = currentMins < 10 ? `0${currentMins}` : currentMins;
  currentSecs = currentSecs < 10 ? `0${currentSecs}` : currentSecs;

  // Current datetime
  // String such as 2016-07-16T19:20:30
  const zDateTime = `${currentYear}-${currentMonth}-${currentDate}T${currentHrs}:${currentMins}:${currentSecs}`;

  // Concatenate datetime and timezone offset to get an ISO-8601 datetime string.
  return zDateTime + timezoneStandard;
}

/**
 * Convert the first character to upper case.
 *
 * Source: https://github.com/vuejs/vue/blob/1.0/src/filters/index.js#L37
 */
export function capitalize(value) {
  if (!value && value !== 0) {
    return "";
  }

  return value.toString().charAt(0).toUpperCase() + value.slice(1);
}

/**
 * Format the given money value.
 *
 * Source: https://github.com/vuejs/vue/blob/1.0/src/filters/index.js#L70
 */
export function currency(value, symbol = "$", addComma = true) {
  let valueVar = parseFloat(value);
  let head = "";
  if (!Number.isFinite(valueVar) || (!valueVar && valueVar !== 0)) {
    return "";
  }
  // We devide by 100 since our prices tables is holding integers only
  valueVar /= 100;
  const stringified = Math.abs(valueVar).toFixed(2);
  const intVal = stringified.slice(0, -1 - 2);
  const i = intVal.length % 3;
  if (addComma) {
    head = i > 0 ? intVal.slice(0, i) + (intVal.length > 3 ? "," : "") : "";
  } else {
    head = i > 0 ? intVal.slice(0, i) + (intVal.length > 3 ? "" : "") : "";
  }
  const floatVal = stringified.slice(-1 - 2);
  const sign = valueVar < 0 ? "-" : "";
  const currencySymbol = symbol;

  return sign + currencySymbol + head + intVal.slice(i).replace(/(\d{3})(?=\d)/g, "$1,") + floatVal;
}

export function currencyInput(value) {
  const valueVar = parseFloat(value);
  if (!Number.isFinite(valueVar) || (!valueVar && valueVar !== 0)) {
    return "";
  }

  const val = (valueVar / 1).toFixed().replace(/,/g, "");
  return (val / 100).toFixed(2);
}

/**
 * Enabled/disabled tooltip message.
 */
export function tooltipToggle(value, enabledMessage, disabledMessage) {
  if (value == true) {
    return enabledMessage;
  }
  return disabledMessage;
}

/**
 * THC Limit filter.
 */
export function hotOrNot(value, category) {
  if (category == "Merchandise") {
    return "N/A";
  }
  if (value) {
    return "Over Limit";
  }
  return "Under Limit";
}

/**
 * Return CBD min or range
 */
export function cdbCount(min, max, unit, category) {
  let value = min;
  let maxValue = max;
  if (category == "Merchandise") {
    return "N/A";
  }
  if (!min) {
    return "Not Entered";
  }
  if (unit) {
    value += unit;
    maxValue += unit;
  }
  if (max) {
    value = `${value} - ${maxValue}`;
  }

  return value;
}

/**
 * Return yes or no
 */
export function yesOrNo(value) {
  if (value === true) {
    return "Yes";
  }
  if (value === false) {
    return "No";
  }
  if (typeof value != "undefined") {
    return "";
  }
  return null;
}

/**
 * Return yes or no
 */
export function yesOrNoInverse(value) {
  if (value === true) {
    return "No";
  }
  if (value === false) {
    return "Yes";
  }
  if (typeof value != "undefined") {
    return "";
  }
  return null;
}

/**
 * Add commas to numbers
 */
export function numberCommas(value) {
  let valueParsed = null;
  if (value) {
    valueParsed = parseInt(value, 10);
  } else {
    return null;
  }
  if (!valueParsed) {
    return null;
  }
  if (valueParsed < 0) {
    valueParsed = 0;
  }
  return valueParsed.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Percentage Helpers
 */
export function percentage(value, symbol = "%") {
  return value + symbol;
}

/**
 * That pesky rounding bug
 */
export function percentageRounded(value, decimals) {
  let workingValue = 0;
  let workingDecimals = 0;
  if (value) {
    workingValue = value;
  }
  if (decimals) {
    workingDecimals = decimals;
  }
  workingValue *= 100;
  workingValue = Math.round(workingValue * 10 ** workingDecimals) / 10 ** workingDecimals;
  workingValue += "%";
  return workingValue;
}

/**
 * Tag Styling
 */
export function tagStyle(value) {
  let style = "";
  if (value.background_color) {
    style = `background: ${value.background_color};`;
  }
  if (value.color) {
    style += `color: ${value.color};`;
  }
  return style;
}

export function truncate(text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  }
  return text;
}

export function humanFileSize(value, si = true, precision = 1) {
  const thresh = si ? 1000 : 1024;
  let valueParam = value;
  if (Math.abs(valueParam) < thresh) {
    return `${valueParam} B`;
  }
  const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** precision;
  do {
    valueParam /= thresh;
    u += 1;
  } while (Math.round(Math.abs(valueParam) * r) / r >= thresh && u < units.length - 1);
  return `${valueParam.toFixed(precision)} ${units[u]}`;
}

/**
 * Filesize Helper
 */
export function getFilesize(value) {
  if (value) {
    return filesize(value);
  }
  return null;
}
