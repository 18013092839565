<template>
  <div class="alert" :class="alertClass">
    <div class="flex-row" style="display: flex">
      <InfoIcon v-if="showIcon" class="my-auto d-none d-sm-inline" :fill="iconFill" :style="iconStyle" />
      <span :class="mainContentClass" style="flex-grow: 4">
        <span v-if="clearable" class="float-right clickable" @click="$emit('clear')"><i class="fa fa-times" /></span>
        <span>
          <slot />
        </span>
        <div v-if="link" class="text-center mt-2">
          <a v-if="linkIsExternal" :href="link" target="_blank">
            <BushelBtn :variant="linkVariant" :outline="linkOutline">
              {{ linkLabel ? linkLabel : link }}
            </BushelBtn>
          </a>
          <BushelBtn v-else :variant="linkVariant" :outline="linkOutline" @click="() => navTo(link)">
            {{ linkLabel ? linkLabel : link }}
          </BushelBtn>
        </div>
        <BushelBtn
          v-if="actionButtonText"
          :variant="actionButtonVariant"
          class="mt-2 py-1 nowrap"
          style="width: 250px"
          :class="actionButtonClass"
          @click="$emit('actionButtonClicked')"
        >
          {{ actionButtonText }}
        </BushelBtn>
      </span>
    </div>
  </div>
</template>
<script>
import InfoIcon from "@/components/common/icons/InfoIcon.vue";
import { navTo } from "@/services/helpers";

export default {
  name: "BushelAlert",
  components: { InfoIcon },
  props: {
    alertClass: { type: String, default: "alert-info" },
    link: { type: String },
    linkLabel: { type: String },
    linkVariant: { type: String, default: "primary" },
    linkOutline: { type: Boolean, default: false },
    linkIsExternal: { type: Boolean, default: false },
    mainContentClass: { type: String, default: "d-inline text-center mt-auto mb-auto" },
    clearable: { type: Boolean, default: false },
    showIcon: { type: Boolean, default: true },
    actionButtonClass: { type: String },
    actionButtonStyle: { type: Object },
    actionButtonText: { type: String },
    actionButtonVariant: { type: String, default: "success" },
    iconStyle: { type: String },
  },
  emits: ["clear", "actionButtonClicked"],
  data() {
    return {};
  },
  computed: {
    iconFill() {
      let color = "#30545f";
      switch (this.alertClass) {
        case "alert-info":
          color = "#30545f";
          break;
        case "alert-warning":
          color = "#856404";
          break;
        case "alert-success":
          color = "#455433";
          break;
        case "alert-danger":
          color = "#852c2c";
          break;
        case "alert-primary":
          color = "#1d212c";
          break;
        case "alert-secondary":
          color = "#383d41";
          break;
        default:
          color = "#30545f";
      }
      return color;
    },
  },
  mounted() {},
  methods: {
    navTo,
  },
};
</script>

<style scoped></style>
