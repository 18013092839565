// Load dayjs, plugins and language packs.
import dayjs from "dayjs";
// import "timeZone-1900-2050", "timeZone-1970-2038"
// or "timeZone-2012-2022" to save your package size
import timeZonePlugin from "dayjs/plugin/timezone.js";
import customParseFormat from "dayjs/plugin/customParseFormat.js";
import localizableFormat from "dayjs/plugin/localizedFormat.js";
import relativeTime from "dayjs/plugin/relativeTime.js";
import IsSameOrBefore from "dayjs/plugin/isSameOrBefore.js";
import IsSameOrAfter from "dayjs/plugin/isSameOrAfter.js";
import IsBetween from "dayjs/plugin/isBetween.js";
import utc from "dayjs/plugin/utc.js";
import Duration from "dayjs/plugin/duration.js";
import localeData from "dayjs/plugin/localeData";
import calendar from "dayjs/plugin/calendar";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

import "dayjs/locale/en";
import "dayjs/locale/cs";
import "dayjs/locale/sk";

// Register plugins and language packs; Czech will be the default language.
dayjs
  .extend(timeZonePlugin)
  .extend(customParseFormat)
  .extend(localizableFormat)
  .extend(relativeTime)
  .extend(IsSameOrBefore)
  .extend(IsSameOrAfter)
  .extend(IsBetween)
  .extend(utc)
  .extend(localeData)
  .extend(Duration)
  .extend(calendar)
  .extend(quarterOfYear);

export default dayjs;
